import { CalculateFromHeight } from "styled/base/SizeFunctions";
import { ArticleSearchInputFont } from "styled/common/font-styles/BlogFonts";
import { FillSpaceMixin, NonSelectable } from "styled/common/Utils";
import styled from "styled-components";

export const ArticleSearcher = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme: { colors } }) => colors.black};
  border-bottom-width: 1px;
  position: relative;
  top: 0;
  right: 0;
  ${CalculateFromHeight("height", { mobile: 14, tablet: 5, desktop: 5 })}
`;

export const ArticleSearchInput = styled.input`
  ${ArticleSearchInputFont}
  width: 100%;
  height: 90%;
  grid-row: 1 / span 1;
  grid-column: 1 / span 7;
  padding: 0;
`;
export const ArticleSearchButton = styled.button`
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;
  height: 60%;
  grid-row: 1 / span 1;
  grid-column: 8 / span 1;
`;

export const ArticleSearchIcon = styled.img`
  ${FillSpaceMixin}
  ${NonSelectable}
`;
