import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled from "styled-components";

export const BlogMainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BlogMainSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }) => colors.black};
  width: 100%;
  position: relative;
  left: 0px;
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 4, desktop: 4 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15, tablet: 15, desktop: 15 })}
`;

export const BlogArticleListWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 13;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 11;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 18;
    grid-row: 2 / span 1;
  }

  ${CalculateFromHeight("min-height", { mobile: 90, tablet: 90, desktop: 60 })}
  ${CalculateFromWidth("margin-right", { mobile: 3, desktop: 2.8 })}
  ${CalculateFromWidth("margin-left", { mobile: -1.5, desktop: -1.5 })}
  ${CalculateFromHeight("margin-bottom", { desktop: 11 })}
`;
export const ArticleSearchSectionWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    position: fixed;
    right: 3%;
    width: 23.5%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    position: fixed;
    right: 5%;
    width: 30%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 18;
    grid-row: 1 / span 1;
    width: 100%;
    height: fit-content;
  }
  ${CalculateFromHeight("top", { tablet: 35, desktop: 29 })}
  ${CalculateFromWidth("margin-left", { desktop: 3.1 })}
`;

export const BlogArticlesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(1, 1fr);
  height: 100%;
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: 1fr, 1fr;
  }
  ${CalculateFromHeight("min-height", { mobile: 50, tablet: 50, desktop: 50 })}
  ${CalculateFromHeight("margin-bottom", { tablet: 8 })}
  ${CalculateFromHeight("margin-top", { tablet: 8 })}
`;
export const BlogLineAbovePagination = styled.div`
  border-bottom-style: solid;
  border-bottom-color: #b5acac;
  border-bottom-width: 1px;
  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: 1, desktop: 1 })}
  ${CalculateFromWidth("margin-bottom", { tablet: 13, desktop: 8 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15 })}
  ${CalculateFromWidth("margin-left", { mobile: 4, tablet: 4, desktop: 4 })}
  ${CalculateFromWidth("margin-right", { mobile: 3, tablet: 3, desktop: 3 })}
`;
export const BlogPaginationWrapper = styled.div`
  width: 93%;
  display: grid;
  grid-template-rows: 1fr;
  position: relative;

  grid-template-columns: repeat(17, 1fr);
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(15, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(15, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 6, tablet: 6, desktop: 6 })}
  ${CalculateFromWidth("margin-left", { mobile: 4, tablet: 4, desktop: 4 })}
`;
