import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { PaginationEllipsisFont } from "styled/common/font-styles/BlogFonts";
import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const PaginationNumberButtonList = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, auto);

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-left: auto;
    margin-right: auto;
  }
  ${CalculateFromWidth("margin-left", { tablet: 10, desktop: 15 })}
  ${CalculateFromWidth("margin-right", { tablet: 10, desktop: 15 })}
`;

export const PaginationNumber = styled.div`
  grid-row: 1 / span 1;
  grid-column: 4 / span 11;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 4 / span 9;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 5 / span 6;
  }
  margin: auto;
`;

export const PaginationEllipsis = styled.div`
  ${PaginationEllipsisFont}
  ${SizeFillSpaceMixin}
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;
