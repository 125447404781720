import React, { RefObject, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";

import {
  PaginationButton,
  PaginationEmptyCircle,
  PaginationFilledCircle
} from "components/pages/blog-common/CircleButton/CircleButton.styled";
import gsap, { Power3 } from "gsap/gsap-core";
import { PaginationNumberButton } from "styled/common/Buttons";

interface CircleButtonProps {
  number: number;
  currentNumberButton: (pageNumber: number) => void;
  refButton: RefObject<HTMLButtonElement>;
  isCurrentPage: number;
}

export const CircleButton = ({
  number,
  currentNumberButton,
  refButton,
  isCurrentPage
}: CircleButtonProps) => {
  const paginationEmptyCircle = useRef<HTMLDivElement>(null);
  const paginationButton = useRef<HTMLDivElement>(null);
  const paginationFilledCircle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (number === isCurrentPage) {
      ActivateButton();
    } else {
      DeactivateButton();
    }
  });

  const ActivateButton = () => {
    gsap.to(paginationEmptyCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.35 });

    if (isMobile) {
      gsap.to(paginationFilledCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.35 });
    } else {
      gsap.to(paginationFilledCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.35 });
    }

    gsap.to(paginationButton.current, { color: "white", scale: 1, delay: 0.1875, duration: 0.1 });
  };

  const DeactivateButton = () => {
    gsap.to(paginationEmptyCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.35 });
    gsap.to(paginationFilledCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.35 });
    gsap.to(paginationButton.current, { color: "black", scale: 1, delay: 0.1875, duration: 0.1 });
  };

  return (
    <PaginationNumberButton
      onMouseEnter={ActivateButton}
      onMouseLeave={number !== isCurrentPage ? DeactivateButton : ActivateButton}
      onClick={() => currentNumberButton(number)}
      ref={refButton}
      tabIndex={-1}
    >
      <PaginationEmptyCircle ref={paginationEmptyCircle}></PaginationEmptyCircle>
      <PaginationFilledCircle
        ref={paginationFilledCircle}
        style={{ transform: "scale(0,0)" }}
      ></PaginationFilledCircle>
      <PaginationButton ref={paginationButton}>{number}</PaginationButton>
    </PaginationNumberButton>
  );
};

export default CircleButton;
