import { CalculateFromHeight } from "styled/base/SizeFunctions";
import { TagsSectionTitleFont } from "styled/common/font-styles/BlogFonts";
import styled from "styled-components";

export const ArticleSearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${CalculateFromHeight("margin-top", { tablet: 25, desktop: 25 })}
`;

export const TagsSectionTitle = styled.div`
  ${TagsSectionTitleFont}
  width: 100%;
  position: relative;
  ${CalculateFromHeight("margin-top", { mobile: 9, tablet: 9, desktop: 9 })}
`;

export const TagListWrapper = styled.div`
  width: 100%;
  display: inline-block;
  ${CalculateFromHeight("margin-top", { mobile: 6, tablet: 2.5, desktop: 2.5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 4, tablet: 2.5 })}
`;
