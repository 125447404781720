import React, { useRef } from "react";

import BlogArticleSearcher from "components/pages/blog/BlogArticleSearcher/BlogArticleSearcher";
import {
  ArticleSearchSection,
  TagListWrapper,
  TagsSectionTitle
} from "components/pages/blog/BlogArticleSearchSection/BlogArticleSearchSection.styled";
import TagsRectangularButton from "components/pages/blog-common/TagsRectangularButton/TagsRectangularButton";
import { setSelectedTag, useBlogContext } from "contexts/BlogContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const BlogArticleSearchSection = () => {
  const tagButton = useRef<HTMLButtonElement>(null);
  const [{ selectedTag, tagList }, dispatch] = useBlogContext();

  useFirstRenderEffect(() => {
    if (selectedTag) {
      setTimeout(() => {
        dispatch(setSelectedTag(""));
      }, 100);
    }
  }, []);

  const handleClick = (clickedTag: string) => {
    if (clickedTag === selectedTag) {
      dispatch(setSelectedTag(""));

      return;
    }

    dispatch(setSelectedTag(clickedTag));
  };

  return (
    <ArticleSearchSection>
      <BlogArticleSearcher />
      <TagsSectionTitle>{Translate("Search_By_Tags")}</TagsSectionTitle>
      <TagListWrapper>
        {tagList.map(tag => (
          <TagsRectangularButton
            key={tag}
            text={Translate(tag)}
            refButton={tagButton}
            isArticle={false}
            onClick={() => handleClick(tag)}
            activeTagButton={selectedTag}
          />
        ))}
      </TagListWrapper>
    </ArticleSearchSection>
  );
};

export default BlogArticleSearchSection;
