import { RefObject } from "react";

import { ARTICLES_PER_PAGE } from "components/pages/blog/BlogArticleList/BlogArticleList";
import gsap from "gsap/gsap-core";

interface UseBlogArticleListPaginationProps {
  articleCount: number;
  currentPage: number;
  allPagesPagination: number;
  changeCurrentPage: (page: number) => void;
  paginationButtonPreviousRef: RefObject<HTMLElement>;
  paginationButtonNextRef: RefObject<HTMLElement>;
}

interface UseBlogArticleListPaginationReturn {
  paginationAnimation: (currentPage: number) => void;
  updatePaginationPrevious: () => void;
  updatePaginationNext: () => void;
  updatePagination: (pageNumber: number) => void;
}

export const useBlogArticleListPagination = ({
  articleCount,
  currentPage,
  allPagesPagination,
  changeCurrentPage,
  paginationButtonPreviousRef,
  paginationButtonNextRef
}: UseBlogArticleListPaginationProps): UseBlogArticleListPaginationReturn => {
  const paginationAnimation = (currentPage: number) => {
    const pageNumbers = [...Array(Math.ceil(articleCount / ARTICLES_PER_PAGE) + 1).keys()].slice(1);

    const gaspPreviousButtonTarget = paginationButtonPreviousRef.current;
    const gaspNextButtonTarget = paginationButtonNextRef.current;
    const gsapVars = { opacity: 0, cursor: "context-menu", pointerEvents: "none", duration: 0.5 };
    const gsapSecondVars = { opacity: 1, cursor: "pointer", pointerEvents: "auto", duration: 0.5 };

    if (pageNumbers.length <= 1) {
      gsap.to(gaspPreviousButtonTarget, gsapVars);
      gsap.to(gaspNextButtonTarget, gsapVars);
    } else {
      if (currentPage <= 1) {
        gsap.to(gaspPreviousButtonTarget, gsapVars);
        gsap.to(gaspNextButtonTarget, gsapSecondVars);
      }

      if (currentPage >= pageNumbers.length) {
        gsap.to(gaspPreviousButtonTarget, gsapSecondVars);
        gsap.to(gaspNextButtonTarget, gsapVars);
      }

      if (currentPage > 1 && currentPage < pageNumbers.length) {
        gsap.to(gaspPreviousButtonTarget, gsapSecondVars);
        gsap.to(gaspNextButtonTarget, gsapSecondVars);
      }
    }
  };

  const updatePaginationPrevious = () => {
    const newPageNumber = Math.max(1, currentPage - 1);

    changeCurrentPage(newPageNumber);
    paginationAnimation(newPageNumber);
  };

  const updatePaginationNext = () => {
    const newPageNumber = Math.min(currentPage, allPagesPagination - 1) + 1;

    changeCurrentPage(newPageNumber);
    paginationAnimation(newPageNumber);
  };

  const updatePagination = (pageNumber: number) => {
    changeCurrentPage(pageNumber);
    paginationAnimation(pageNumber);
  };

  return { paginationAnimation, updatePaginationPrevious, updatePaginationNext, updatePagination };
};
