import React, { useRef } from "react";

import {
  Subtitle,
  type SubtitleVariant
} from "components/common/title/PageSubtitle/PageSubtitle.styled";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

interface PageSubtitleProps {
  subtitle: {
    variant: SubtitleVariant;
    text: string;
  };
}

export const PageSubtitle: Component<PageSubtitleProps> = ({
  subtitle: { variant, text },
  children
}) => {
  const subtitleText = useRef<HTMLHeadingElement>(null);

  useFirstRenderEffect(() => {
    gsap.to(subtitleText.current, { opacity: 1, duration: 1, ease: Circ.easeOut, delay: 2 });
  });

  return (
    <Subtitle ref={subtitleText} variant={variant} style={{ opacity: 0 }}>
      {Translate(text)}
      {children}
    </Subtitle>
  );
};

export default PageSubtitle;
