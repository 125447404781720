import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { BlogSubtitleFont } from "styled/common/font-styles/BlogFonts";
import {
  AutomationSubtitleFont,
  CommonTitleDescriptionFont
} from "styled/common/font-styles/CommonFonts";
import { HomeSubtitleFont } from "styled/common/font-styles/HomeFonts";
import styled, { css } from "styled-components";

export type SubtitleVariant =
  | "about-subtitle"
  | "blog-subtitle"
  | "home-subtitle"
  | "projects-subtitle"
  | "game-development-subtitle"
  | "web-development-subtitle"
  | "automation-subtitle";

const AboutSubtitle = css`
  ${CommonTitleDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 10 / span 3;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 5 / span 7;
    grid-row: 5 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 12;
    grid-row: 5 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 12 })}
  ${CalculateFromWidth("margin-left", { mobile: 6, tablet: 4, desktop: -7.375 })}
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 7, desktop: 3 })}
`;

const BlogSubtitle = css`
  ${BlogSubtitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-top: auto;
    grid-column: 8 / span 5;
    grid-row: 4 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 10;
    grid-row: 5 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 9;
    grid-row: 5 / span 2;
  }
  ${CalculateFromHeight("margin-bottom", { desktop: 2 })}
  ${CalculateFromHeight("margin-top", { tablet: 3 })}
  ${CalculateFromWidth("margin-left", { mobile: 6, tablet: 5 })}
  ${CalculateFromWidth("margin-right", { desktop: 6 })}
`;

const HomeSubtitle = css`
  ${HomeSubtitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 10 / span 4;
    grid-row: 3 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 5;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 8;
    grid-row: 2 / span 2;
  }
  ${CalculateFromWidth("margin-left", { tablet: -2.5, desktop: 5 })}
  ${CalculateFromHeight("margin-top", { desktop: 5.5 })}
`;

const ProjectsSubtitle = css`
  ${CommonTitleDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 10;
    grid-row: 4 / span 5;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 9;
    grid-row: 3 / span 5;
  }

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 9 / span 4;
    grid-row: 2 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 20, tablet: 10.5, desktop: 3.5 })}
  ${CalculateFromWidth("margin-left", { mobile: 8, tablet: 13, desktop: 4 })}
  ${CalculateFromWidth("margin-right", { mobile: 6, tablet: 5, desktop: 3 })}
`;

const AutomationSubtitle = css`
  ${AutomationSubtitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 11 / span 12;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 7;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 10;
    grid-row: 1 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 10, desktop: -5 })}
  ${CalculateFromWidth("margin-left", { mobile: 6, tablet: 4, desktop: -7.375 })}
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 7, desktop: 0 })}
`;

const GameDevelopmentSubtitle = css`
  ${CommonTitleDescriptionFont}
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: 8, desktop: -3 })}
  ${CalculateFromWidth("margin-left", { mobile: 8, tablet: 8, desktop: 4 })}
  ${CalculateFromWidth("margin-right", { mobile: 6, tablet: 5, desktop: 3 })}

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 9 / span 3;
    grid-row: 2 / span 1;
  }

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 6;
    grid-row: 1 / span 4;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 7;
    grid-row: 1 / span 4;
  }
`;

const WebDevelopmentSubtitle = css`
  ${CommonTitleDescriptionFont}
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: 8, desktop: -3 })}
  ${CalculateFromWidth("margin-left", { mobile: 8, tablet: 8, desktop: 4 })}
  ${CalculateFromWidth("margin-right", { mobile: 6, tablet: 5, desktop: 3 })}

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 9 / span 3;
    grid-row: 2 / span 1;
  }

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 6;
    grid-row: 1 / span 4;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 7;
    grid-row: 1 / span 4;
  }
`;

export const Subtitle = styled.h2<{ variant: SubtitleVariant }>`
  ${({ variant }) => variant === "about-subtitle" && AboutSubtitle}
  ${({ variant }) => variant === "blog-subtitle" && BlogSubtitle}
  ${({ variant }) => variant === "home-subtitle" && HomeSubtitle}
  ${({ variant }) => variant === "web-development-subtitle" && WebDevelopmentSubtitle}
  ${({ variant }) => variant === "projects-subtitle" && ProjectsSubtitle}
  ${({ variant }) => variant === "automation-subtitle" && AutomationSubtitle}
  ${({ variant }) => variant === "game-development-subtitle" && GameDevelopmentSubtitle}
`;
