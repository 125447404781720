import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled, { css } from "styled-components";

export type ScrollIconWrapperVariant = "home-scroll-icon-wrapper";

const HomeScrollIconWrapper = css`
  ${CalculateFromHeight("margin-top", { mobile: 1.5, tablet: 1.5, desktop: 4.5 })}
`;

export const ScrollIconWrapper = styled.div<{ variant?: ScrollIconWrapperVariant }>`
  ${({ variant }) => variant === "home-scroll-icon-wrapper" && HomeScrollIconWrapper}
`;

export const ScrollIconContent = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    border: white solid;
  }
  ${CalculateFromWidth("width", { desktop: 1.6 })}
  ${CalculateFromWidth("height", { desktop: 2.6 })}
  ${CalculateFromWidth("border-width", { desktop: 0.1 })}
  ${CalculateFromWidth("border-radius", { desktop: 10 })}
`;

export const ScrollIconInterior = styled.div`
  margin-top: 30%;
  max-height: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  ${CalculateFromWidth("width", { mobile: 0.15, tablet: 0.15, desktop: 0.15 })}
`;
