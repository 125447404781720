import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { PaginationButtonFont } from "styled/common/font-styles/BlogFonts";
import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const PaginationEmptyCircle = styled.div`
  border-style: solid;
  border-color: #c5c2c2;
  opacity: 0.8;
  border-width: 1px;
  border-radius: 100%;
  ${SizeFillSpaceMixin}
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;

export const PaginationFilledCircle = styled.div`
  ${SizeFillSpaceMixin}
  background-color: ${({ theme: { colors } }) => colors.black};
  border-radius: 100%;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;

export const PaginationButton = styled.div`
  ${CalculateFromWidth("margin-left", { mobile: 0.2, tablet: 0.1, desktop: 0.1 })}
  ${CalculateFromWidth("padding-top", { desktop: 0.1 })}
  ${PaginationButtonFont}
  width: 100%;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  height: auto;
  margin: auto;
`;
