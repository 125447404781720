import React, { useRef } from "react";

import {
  ScrollIconContent,
  ScrollIconInterior,
  ScrollIconWrapper,
  type ScrollIconWrapperVariant
} from "components/common/title/ScrollIcon/ScrollIcon.styled";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

interface ScrollIconProps {
  variant?: ScrollIconWrapperVariant;
}

export const ScrollIcon = ({ variant }: ScrollIconProps) => {
  const scrollWrapper = useRef<HTMLDivElement>(null);
  const scrollInterior = useRef<HTMLDivElement>(null);

  useFirstRenderEffect(() => {
    gsap.to(scrollWrapper.current, { opacity: 1, duration: 1, delay: 2 });

    const timeline = gsap.timeline({ repeat: 9999, delay: 3 });

    timeline.delay(0.25);
    timeline.to(
      scrollInterior.current,
      { marginTop: "30%", paddingBottom: "70%", opacity: 1, duration: 0.5, ease: Circ.easeInOut },
      2
    );
    timeline.delay(0.25);
    timeline.to(
      scrollInterior.current,
      { marginTop: "100%", paddingBottom: 0, opacity: 0, duration: 0.75, ease: Circ.easeOut },
      4
    );
    timeline.to(
      scrollInterior.current,
      { marginTop: "30%", paddingBottom: "70%", opacity: 0, duration: 0, ease: Circ.easeInOut },
      5
    );
    timeline.play();
  });

  return (
    <ScrollIconWrapper variant={variant}>
      <ScrollIconContent ref={scrollWrapper} style={{ opacity: 0 }}>
        <ScrollIconInterior ref={scrollInterior} />
      </ScrollIconContent>
    </ScrollIconWrapper>
  );
};

export default ScrollIcon;
