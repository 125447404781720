import React from "react";
import { Helmet } from "react-helmet";

import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import Layout from "components/layout/Layout/Layout";
import WhiteBackground from "components/layout/WhiteBackground/WhiteBackground";
import BlogArticleList from "components/pages/blog/BlogArticleList/BlogArticleList";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const Blog = ({ location }: PageProps) => {
  return (
    <>
      <Helmet defer={false}>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GatsbySeo {...GetSeoData("Blog")} />
      <Layout location={location}>
        <WhiteBackground type="blog" />
        <MainCloud color="VioletBlack" variant="BlogCloudWrapper" scrollSpeed={0.5} />
        <PageTitle {...pageTitleData} />
        <BlogArticleList />
      </Layout>
    </>
  );
};

export default Blog;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "blog-title-wrapper",
  isTitle: true,
  headers: [
    {
      text: "Blog_Header",
      variant: "BlogTitle",
      id: "blog",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    }
  ],
  subtitle: {
    text: "Blog_Header_Subtitle",
    variant: "blog-subtitle"
  }
};
