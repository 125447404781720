import * as React from "react";

import {
  ArticleEmptyPreviewText,
  ArticlePreviewContainer,
  ArticlePreviewEmpty,
  ArticlePreviewReference
} from "components/pages/blog-common/ArticlePreview/ArticlePreview.styled";
import Translate from "utils/translation/translation";

export const ArticleEmptyPreview = () => {
  return (
    <ArticlePreviewContainer>
      <ArticlePreviewReference>
        <ArticlePreviewEmpty>
          <ArticleEmptyPreviewText>{Translate("Blog_No_Articles_Found")}</ArticleEmptyPreviewText>
        </ArticlePreviewEmpty>
      </ArticlePreviewReference>
    </ArticlePreviewContainer>
  );
};

export default ArticleEmptyPreview;
