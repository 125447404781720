import * as React from "react";

import HeaderGroup, { type HeadersProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  type PageTitleVariant,
  PageTitleWrapper
} from "components/common/other/PageTitle/PageTitle.styled";
import PageSubtitle from "components/common/title/PageSubtitle/PageSubtitle";
import type { SubtitleVariant } from "components/common/title/PageSubtitle/PageSubtitle.styled";
import ScrollIcon from "components/common/title/ScrollIcon/ScrollIcon";
import type { ScrollIconWrapperVariant } from "components/common/title/ScrollIcon/ScrollIcon.styled";

export interface PageTitleProps {
  instantPlayback: boolean;
  variant: PageTitleVariant;
  isTitle: boolean;
  headers: HeadersProps[];
  subtitle?: {
    text: string;
    variant: SubtitleVariant;
  };
  showScrollIcon?: string;
  scrollIconVariant?: ScrollIconWrapperVariant;
}

export const PageTitle = ({
  variant,
  subtitle,
  showScrollIcon,
  scrollIconVariant,
  headers,
  instantPlayback,
  isTitle
}: PageTitleProps) => {
  return (
    <PageTitleWrapper variant={variant}>
      <HeaderGroup instantPlayback={instantPlayback} headers={headers} isTitle={isTitle} />
      {typeof subtitle !== "undefined" && (
        <PageSubtitle subtitle={subtitle}>
          {showScrollIcon === "true" && <ScrollIcon variant={scrollIconVariant} />}
        </PageSubtitle>
      )}
    </PageTitleWrapper>
  );
};

export default PageTitle;
