import React, { useRef, useState } from "react";

import {
  PaginationEllipsis,
  PaginationNumber,
  PaginationNumberButtonList
} from "components/pages/blog/BlogPagination/BlogPagination.styled";
import CircleButton from "components/pages/blog-common/CircleButton/CircleButton";
import { PaginationNumberButton } from "styled/common/Buttons";
import { GetPageNumbers } from "utils/blog-helpers";

interface BlogPaginationProps {
  articlePerPage: number;
  allArticles: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
}

type PaginationElements = {
  number: number | "...";
};

export const BlogPagination = ({
  articlePerPage,
  allArticles,
  paginate,
  currentPage
}: BlogPaginationProps) => {
  const [currentNumberButton, setCurrentNumberButton] = useState(currentPage);
  const circleButtonRef = useRef<HTMLButtonElement>(null);

  const CreatePaginationElements = ({ number }: PaginationElements) => {
    return number === "..." ? (
      <PaginationNumberButton key={Math.random()}>
        <PaginationEllipsis />
        <PaginationEllipsis>{number}</PaginationEllipsis>
      </PaginationNumberButton>
    ) : (
      <CircleButton
        isCurrentPage={currentPage}
        key={number}
        number={number}
        refButton={circleButtonRef}
        currentNumberButton={pageNumber => {
          setCurrentNumberButton(pageNumber);
          paginate(pageNumber);
        }}
      />
    );
  };

  return (
    <PaginationNumber>
      <PaginationNumberButtonList>
        {GetPageNumbers(currentNumberButton, allArticles, articlePerPage).map(number => {
          return <CreatePaginationElements key={number} number={number} />;
        })}
      </PaginationNumberButtonList>
    </PaginationNumber>
  );
};

export default BlogPagination;
