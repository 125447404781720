import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled, { css } from "styled-components";

export type PageTitleVariant =
  | "not-found-title"
  | "project-title"
  | "project-title-long"
  | "projects-title"
  | "about-title"
  | "blog-title-wrapper"
  | "home-title"
  | "game-development-title"
  | "web-development-title"
  | "automation-title";

const NotFoundTitle = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(6, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(6, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(6, 1fr);
  }
  ${CalculateFromHeight("margin-top", { mobile: 11, desktop: 13 })}
  ${CalculateFromHeight("height", { mobile: 70, tablet: 50, desktop: 50 })}
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  width: 100%;
`;

const ProjectTitle = css`
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 20, tablet: 10 })}
  ${CalculateFromHeight("margin-bottom", { tablet: 10, desktop: 10 })}
  ${CalculateFromWidth("width", { mobile: 90, tablet: 90, desktop: 100 })}
  ${CalculateFromHeight("height", { mobile: 50, tablet: 40, desktop: 60 })}
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);
`;

const ProjectTitleLong = css`
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 10, tablet: 10, desktop: 10 })}
  ${CalculateFromHeight("margin-bottom", { tablet: 10, desktop: 10 })}
  ${CalculateFromWidth("width", { mobile: 90, tablet: 90, desktop: 100 })}
  ${CalculateFromHeight("height", { mobile: 70, tablet: 50, desktop: 80 })}
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 1fr);
`;

const ProjectsTitle = css`
  ${CalculateFromHeight("margin-bottom", { mobile: 10, tablet: 10 })}
  ${CalculateFromHeight("margin-top", { mobile: -2, tablet: -2, desktop: 2.8 })}
  ${CalculateFromHeight("height", { mobile: 100, tablet: 100, desktop: 100 })}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, 1fr);
`;

const AboutTitle = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(6, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(6, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(7, 1fr);
  }
  ${CalculateFromHeight("margin-top", { mobile: 11, desktop: 13 })}
  ${CalculateFromHeight("height", { mobile: 120, tablet: 90, desktop: 80 })}
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  width: 100%;
`;

const BlogTitleWrapper = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 1fr);
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 3, desktop: 5 })}
  ${CalculateFromHeight("height", { mobile: 55, tablet: 40, desktop: 40 })}
`;

const HomeTitle = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(7, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 10, desktop: 10 })}
  ${CalculateFromHeight("margin-top", { tablet: 15 })}
  ${CalculateFromHeight("height", { mobile: 140, tablet: 110, desktop: 100 })}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
`;

const AutomationTitle = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(7, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 10 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 15 })}
  ${CalculateFromHeight("height", { mobile: 140, tablet: 110, desktop: 75 })}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
`;

const GameDevelopmentTitle = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
  ${CalculateFromHeight("margin-top", { mobile: -2, tablet: -2, desktop: 2.8 })}

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(7, 1fr);
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(8, 1fr);
  }
`;

const WebDevelopmentTitle = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
  ${CalculateFromHeight("margin-top", { mobile: -2, tablet: -2, desktop: 2.8 })}

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(7, 1fr);
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(8, 1fr);
  }
`;

export const PageTitleWrapper = styled.section<{ variant: PageTitleVariant }>`
  ${({ variant }) => variant === "project-title" && ProjectTitle}
  ${({ variant }) => variant === "project-title-long" && ProjectTitleLong}
  ${({ variant }) => variant === "projects-title" && ProjectsTitle}
  ${({ variant }) => variant === "about-title" && AboutTitle}
  ${({ variant }) => variant === "blog-title-wrapper" && BlogTitleWrapper}
  ${({ variant }) => variant === "home-title" && HomeTitle}
  ${({ variant }) => variant === "automation-title" && AutomationTitle}
  ${({ variant }) => variant === "game-development-title" && GameDevelopmentTitle}
  ${({ variant }) => variant === "web-development-title" && WebDevelopmentTitle}
  ${({ variant }) => variant === "not-found-title" && NotFoundTitle}
`;
