import React, { useRef, useState } from "react";

import {
  ArticleSearchButton,
  ArticleSearcher,
  ArticleSearchIcon,
  ArticleSearchInput
} from "components/pages/blog/BlogArticleSearcher/BlogArticleSearcher.styled";
import { setSelectedFilterWord, useBlogContext } from "contexts/BlogContext";
import SearchIcon from "media/images/icons/search-icon.svg";
import Translate from "utils/translation/translation";

export const BlogArticleSearcher = () => {
  const [filteredWord, setFilteredWord] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);
  const [, dispatch] = useBlogContext();

  const handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(setSelectedFilterWord(filteredWord));
    }
  };

  const handleClick = () => {
    dispatch(setSelectedFilterWord(filteredWord));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredWord(event.target.value);
  };

  return (
    <ArticleSearcher>
      <ArticleSearchInput
        onChange={handleChange}
        onKeyPress={handleSubmit}
        placeholder={Translate("Article_Searcher")}
        ref={input}
      ></ArticleSearchInput>
      <ArticleSearchButton onClick={handleClick}>
        <ArticleSearchIcon src={SearchIcon} alt="Search" />
      </ArticleSearchButton>
    </ArticleSearcher>
  );
};

export default BlogArticleSearcher;
