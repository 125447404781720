import React, { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import useSize from "@react-hook/size";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import {
  ArticleSearchSectionWrapper,
  BlogArticleListWrapper,
  BlogArticlesSection,
  BlogLineAbovePagination,
  BlogMainSection,
  BlogMainSectionWrapper,
  BlogPaginationWrapper
} from "components/pages/blog/BlogArticleList/BlogArticleList.styled";
import { useBlogArticleListPagination } from "components/pages/blog/BlogArticleList/useBlogArticleListPagination";
import BlogArticleSearchSection from "components/pages/blog/BlogArticleSearchSection/BlogArticleSearchSection";
import BlogPagination from "components/pages/blog/BlogPagination/BlogPagination";
import ArticleEmptyPreview from "components/pages/blog-common/ArticlePreview/ArticleEmptyPreview";
import ArticlePreview from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import RectangularButton from "components/pages/blog-common/RectangularButton/RectangularButton";
import { useBlogContext } from "contexts/BlogContext";
import gsap from "gsap/gsap-core";
import { useChangeScrollbarColor } from "hooks/useChangeScrollbarColor";
import LocomotiveScroll from "locomotive-scroll";
import Translate from "utils/translation/translation";
import { isScrollUndefined } from "utils/window-utils";

export const ARTICLES_PER_PAGE = 8;

export const BlogArticleList = () => {
  const paginationButtonPreviousRef = useRef<HTMLButtonElement>(null);
  const paginationButtonNextRef = useRef<HTMLButtonElement>(null);
  const articleListRef = useRef<HTMLDivElement>(null);
  const blogMainSectionWrapperRef = useRef<HTMLDivElement>(null);
  const articleListSize = useSize(articleListRef);
  const [{ selectedArticlesPreviews, selectedTag, selectedFilterWord }] = useBlogContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [allPagesPagination, setAllPagesPagination] = useState(1);
  const [articleListHeight, setArticleListHeight] = useState("");
  const articleCount = useMemo(() => selectedArticlesPreviews.length, [selectedArticlesPreviews]);
  const { updatePaginationPrevious, updatePaginationNext, updatePagination, paginationAnimation } =
    useBlogArticleListPagination({
      articleCount,
      paginationButtonPreviousRef,
      paginationButtonNextRef,
      currentPage,
      changeCurrentPage: (page: number) => setCurrentPage(page),
      allPagesPagination
    });

  useChangeScrollbarColor({ ref: blogMainSectionWrapperRef });

  const getCurrentArticlesList = useMemo(() => {
    const list = selectedArticlesPreviews
      .slice(currentPage * ARTICLES_PER_PAGE - ARTICLES_PER_PAGE, currentPage * ARTICLES_PER_PAGE)
      .map(currentArticle => (
        <ArticlePreview
          key={currentArticle.node.id}
          articleDetails={currentArticle}
          isRecommendedArticle={false}
        />
      ));

    return list.length > 0 ? list : <ArticleEmptyPreview />;
  }, [selectedArticlesPreviews, currentPage]);

  const resizeObserver = () => {
    setArticleListHeight(
      articleListRef.current ? `${articleListRef.current.getBoundingClientRect().height}px` : "0px"
    );

    if (window.innerWidth < 767) {
      setArticleListHeight("auto");
    }
  };

  const handleScrollToTop = () => {
    if (isScrollUndefined()) {
      return;
    }

    const scrollToTarget = 98;

    ((window.scroll as any).scroll as LocomotiveScroll).scrollTo(scrollToTarget);
  };

  useEffect(() => {
    gsap.to(paginationButtonPreviousRef.current, { opacity: 0, duration: 0 });

    if (articleCount <= ARTICLES_PER_PAGE) {
      gsap.to(paginationButtonNextRef.current, { opacity: 0, duration: 0 });
    } else {
      gsap.to(paginationButtonNextRef.current, { opacity: 1, duration: 0 });
    }

    if (Math.floor(articleCount / ARTICLES_PER_PAGE) < articleCount / ARTICLES_PER_PAGE) {
      setAllPagesPagination(Math.floor(articleCount / ARTICLES_PER_PAGE) + 1);
    } else {
      setAllPagesPagination(Math.floor(articleCount / ARTICLES_PER_PAGE));
    }

    paginationAnimation(1);
  }, [articleCount]);

  useEffect(() => {
    window.addEventListener("resize", resizeObserver);

    return () => {
      window.removeEventListener("resize", resizeObserver);
    };
  }, []);

  useEffect(() => {
    resizeObserver();
  }, [articleListSize[1]]);

  useEffect(() => {
    setCurrentPage(1);
    paginationAnimation(1);
  }, [selectedTag, selectedFilterWord]);

  return (
    <BlogMainSectionWrapper ref={blogMainSectionWrapperRef}>
      <BlogMainSection>
        <BlogArticlesSection>
          <BlogArticleListWrapper ref={articleListRef}>
            {getCurrentArticlesList}
          </BlogArticleListWrapper>
          <ArticleSearchSectionWrapper
            id="article-search-section-wrapper"
            style={{ height: `${articleListHeight}` }}
          >
            <LocomotiveElement
              scrollSticky="top"
              scrollDelay={0.55}
              scrollTarget="#article-search-section-wrapper"
              shouldAnimate={!isMobile}
            >
              <BlogArticleSearchSection />
            </LocomotiveElement>
          </ArticleSearchSectionWrapper>
        </BlogArticlesSection>
        <BlogLineAbovePagination
          style={{ display: articleCount > ARTICLES_PER_PAGE ? undefined : "none" }}
        />
        <BlogPaginationWrapper
          style={{ display: articleCount > ARTICLES_PER_PAGE ? undefined : "none" }}
        >
          <RectangularButton
            onClick={() => {
              updatePaginationPrevious();
              handleScrollToTop();
            }}
            text={Translate("Button_Previous_Post")}
            variant="previous"
            direction="left"
            refButton={paginationButtonPreviousRef}
            disabled={currentPage === 1}
          />
          <BlogPagination
            articlePerPage={ARTICLES_PER_PAGE}
            allArticles={articleCount}
            paginate={pageNumber => {
              updatePagination(pageNumber);
              handleScrollToTop();
            }}
            currentPage={currentPage}
          />
          <RectangularButton
            onClick={() => {
              updatePaginationNext();
              handleScrollToTop();
            }}
            text={Translate("Button_Next_Post")}
            variant="next"
            direction="right"
            refButton={paginationButtonNextRef}
            disabled={currentPage === allPagesPagination}
          />
        </BlogPaginationWrapper>
      </BlogMainSection>
    </BlogMainSectionWrapper>
  );
};

export default BlogArticleList;
